import { format, thumbnail } from "../util/filepreview";

(function (root, Delegate, api, postal, page, qs) {
  function setup(settings) {
    _.each(
      root.querySelectorAll(
        "form[data-record='violation'] input[name='tenant']"
      ),
      function (input) {
        input.disabled = _.get(settings, "tenant.request") !== true;
        input
          .closest("label")
          .classList[input.disabled ? "add" : "remove"]("disabled");
      }
    );
  }

  postal.subscribe({
    topic: "location.updated",
    callback: function (data, envelope) {
      // save new on update
      setup(_.get(data, "item.violations"));

      // save to storage for first run
    },
  });

  //console.log("initing violation.create");

  _.defer(function () {
    var section = root.querySelector("aside[data-record='violations/new']");

    var form = section.querySelector("form");
    form.setAttribute("novalidate", "");
    var count = form.querySelector("input[name='photos']");

    var localUrls = {};
    var uploads = [];
    var latitude;
    var longitude;
    //var counter = 0;

    // function imgLoaded(url) {
    //     return new Promise(function(resolve, reject) {
    //         var img = document.createElement("img");
    //         img.addEventListener("load", function() {
    //             resolve(this.src);
    //         });
    //         img.setAttribute("src", url);
    //         if(!!img.complete) resolve(img.src);
    //     });
    // };

    // this one does everything based on eventing, self contained, no promises
    function dispose(id) {
      var localUrl = _.get(localUrls, id);
      if (!!localUrl) URL.revokeObjectURL(localUrl);
      _.unset(localUrls, id);
    }
    function replaceImage(figure, url, id) {
      if (!url) return;

      // contains will determine whether or not the figure has already been removed from the domDelegate

      if (!figure || !root.contains(figure)) {
        dispose(id);
        return; // nothing more to do
      }

      var img = document.createElement("img");
      var load = function () {
        if (!!root.contains(figure))
          figure.style.backgroundImage = "url('" + url + "')";
        dispose(id);
      };
      img.addEventListener("load", load);
      img.setAttribute("src", url);
      if (!!img.complete) load();
    }

    var URL = window.URL || window.webkitURL;

    var sectionDelegate = new Delegate(section);

    sectionDelegate.on("change", "input[type='file']", function (e) {
      //console.log("file.onchange");

      if (!this.files || this.files.length <= 0) return;

      var form = this.closest("form");

      count.value = uploads.length + this.files.length; // update counter;

      _.each(this.files, function (file) {
        //var id = "file" + counter;

        var item = document.createElement("li");
        item.classList.add("photo", "file");
        var list = form.querySelector("ul.photos");
        list.prepend(item);
        list.classList.remove("empty");
        var input = document.createElement("input");
        input.setAttribute("type", "hidden");
        item.append(input);
        var figure = document.createElement("figure");
        item.append(figure);

        var localUrl = URL.createObjectURL(file);
        var id = localUrl;
        _.set(localUrls, id, localUrl);
        figure.style.backgroundImage = "url('" + localUrl + "')";

        var upload = api.Files.create(api.Location.id(), file, {
          latitude: latitude,
          longitude: longitude,
        }).then(function (file) {
          input.setAttribute("name", "photo");
          input.setAttribute("value", file.uuid);
          replaceImage(
            figure, thumbnail(file, 80, 80)); //https://parking-uploads.imgix.net/" + file.uuid + "/" + file.name + "?w=120&h=120&fit=max&auto=compress,enhance", id);
          return file;
        });

        uploads.push(upload);
      });

      //console.log(photos.length);
      //console.log(photos);

      this.value = null;
      this.replaceWith(this.cloneNode());
      //inited(photos);
    });

    sectionDelegate.on("change", "select[name='description']", function (e) {
      //console.log("reason");
      if (this.selectedIndex != 0) {
        if (!notes) {
          form = form || this.closest("form");
          if (!form) return;
          var notes = form.querySelector("textarea[name='notes']");
        }

        if (!notes) return;

        this.blur();

        notes.focus();
      }
    });

    sectionDelegate.on("submit", "form", function (e) {
      e.preventDefault();

      const form = this;

      // make sure only name because otherwise will grab in-process file inputs
      const disable = _.filter(
        form.querySelectorAll("button,input[name],textarea,select"),
        function (input) {
          input.blur();
          return !input.disabled;
        }
      );

      function handleError(error) {
        form.classList.remove("submitting");
        disable.forEach((input) => (input.disabled = false));
        console.error("error submitting form:", error);
        alert(error.message || "Whoops, something went wrong");
      }

      try {
        if (
          disable.filter((input) => Boolean(input.required) && !input.value)
            ?.length
        )
          return; // required with no value

        if (form.classList.contains("submitting")) return;
        form.classList.add("submitting");

        const formData = new FormData();
        const queryize = [
          "vehicle",
          "tenant",
          "space",
          "media",
          "warning",
          "description",
          "photos",
        ];
        var query = {};
        _.each(
          _.assign(formToObject(form), {
            photo: _.map(form.querySelectorAll("input[name='photo']"), "value"),
          }),
          function (val, name) {
            if (_.includes(queryize, name)) _.set(query, name, val);
            if (_.isArray(val))
              _.each(val, function (val) {
                formData.append(name, val);
              });
            else formData.append(name, val);
          }
        );

        disable.forEach((input) => (input.disabled = true));

        const requested = new Date().toISOString();

        Promise.all(uploads)
          .then(() => {
            _.each(
              form.querySelectorAll("button,input[name],textarea,select"),
              function (input) {
                if (Boolean(input.disabled)) return;
                if (!input.name) return;
                formData.append(input.name, input.value); // append to formdata
              }
            );

            return Promise.join(
              api.base(),
              api.Location.id(),
              "/violations?ts=" + requested + "&" + qs.stringify(query),
              (base, location, url) => {
                return api.fetch(
                  "POST",
                  base + "v1/locations/" + location + url,
                  formData,
                  api.Auth.header(location)
                );
              }
            );
          })
          .then((json) => {
            json.requested = requested;

            const item = _.get(json, [
              "violations",
              "items",
              _.get(json, "violations.item"),
            ]);

            if (!item) return null;

            item.__type = "violation";

            postal.publish({
              topic: "violation.created",
              data: {
                generated: json.generated,
                item: item,
              },
            });

            return {
              item: item,
              generated: json.generated,
            };
          })
          .then(() => {
            form.classList.remove("submitting");
            disable.forEach((input) => (input.disabled = false));
            if (form.classList.contains("repeat"))
              form.classList.add("initing");
            form.reset();
          })
          .catch((error) => {
            handleError(error);
            return Promise.reject(error);
          });
      } catch (error) {
        handleError(error.message);
      }
    });

    sectionDelegate.on("reset", "form", function (e) {
      var active = root.getAttribute("data-action");
      if (
        !!active &&
        _.every(
          section.getAttribute("data-record").split("/"),
          function (part) {
            return active.indexOf(part) >= 0;
          }
        )
      ) {
        root.removeAttribute("data-action");
      }

      // photos cleanup
      uploads = [];
      count.value = "";

      _.each(localUrls, function (value, key) {
        URL.revokeObjectURL(value);
        _.unset(localUrls, key);
      });

      var list = this.querySelector("ul.photos");
      while (list.firstChild) list.firstChild.remove();
    });

    sectionDelegate.on("violation.new", function (e) {
      var view = _.get(e, "detail.view");

      form.reset();

      root.setAttribute("data-action", view);

      // trigger geolocation
      if (
        "geolocation" in navigator &&
        !!navigator.geolocation.getCurrentPosition
      ) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            latitude = position.coords.latitude;
            longitude = position.coords.longitude;
            _.each(
              document.querySelectorAll(
                "input[name='latitude'],input[name='longitude']"
              ),
              function (input) {
                input.setAttribute(
                  "value",
                  (input.value = position.coords[input.name])
                );
              }
            );
          },
          function (error) {},
          {
            enableHighAccuracy: true,
            maximumAge: 0,
          }
        );
      }
    });

    var rootDelegate = new Delegate(root);
    rootDelegate.on("click", "a[href='violations/new']", function (e) {
      e.preventDefault();

      var view = this.getAttribute("href");

      form.dispatchEvent(
        new CustomEvent("violation.new", {
          bubbles: true,
          detail: {
            view: view,
          },
        })
      );
    });
  });
})(
  document.documentElement,
  window.Delegate || window.domDelegate.Delegate,
  ParkIQ.API,
  window.postal,
  page,
  window.qs || window.Qs
);

export default self;
