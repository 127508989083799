import { updateItems } from "@parkingboss/svelte-utils";
import { toZoneISOString } from "./datetime";
import {
  merge,
  get,
  pick,
  map,
  has,
  each,
  set,
  find,
  every,
  unset,
  orderBy,
  debounce,
  keyBy,
  invoke,
} from "lodash-es";
import { image } from "./imagetools";

import { api, auth } from "./auth";

function updateFor(key, value) {
  if (!key || !value) return;

  // value can be a key containing a for or the map itself?
  const mapFor = value["for"] || value;

  items.update((state) => {
    if (!state[key]) state[key] = {};
    if (!state[key]["for"]) state[key]["for"] = {};
    Object.assign(state[key]["for"], mapFor); // update or add the items by key
    return state;
  });
}

export { api, auth };

// update auth header as auth changes
export let authHeader = "";
auth.subscribe(
  ($auth) =>
    (authHeader = $auth && `&Authorization=${$auth.type} ${$auth.token}`)
);
auth.subscribe((auth) => auth && invoke(window.ParkIQ, "API.Auth.set", auth));

export function viewpoint(offset) {
  return encodeURIComponent(
    toZoneISOString(new Date().getTime() + (offset || 0))
  );
}

const apiVersion = "v2";

export function base() {
  return api.settings.apiBase + apiVersion;
}

// let authHeader = "";
// api.user.subscribe(auth => auth && (authHeader = `Authorization=${auth.type} ${auth.token}`));
// api.user.subscribe(auth => auth && invoke(window.ParkIQ, "API.Auth.set", auth));
//api.user.subscribe(auth => console.log("auth=", auth));

// lets tap into the old api requests...
const postal = window.postal;
//console.log(postal);
// let untap = postal.addWireTap( function(data, envelope) {
//     console.log("postal:", data, envelope);
// });
if (postal)
  postal.subscribe({
    //channel: "*",
    topic: "*.items.updated",
    callback: function (data, envelope) {
      //console.log("postal: ", data, envelope);
      if (data && data.items)
        updateItems({
          items: keyBy(data.items, (item, keyOrID) => item.id),
        });
      // `data` is the data published by the publisher.
      // `envelope` is a wrapper around the data & contains
      // metadata about the message like the channel, topic,
      // timestamp and any other data which might have been
      // added by the sender.
    },
  });

// export const auth = writable(store.get("auth"));
// // write to backing store
// auth.subscribe($auth => $auth && store.set("auth", $auth));

function formDataToURLSearchParams(formData) {
  return new URLSearchParams([...formData.entries()]); // what edge does this support?
}

export function coordsToURLSearchParams(coords) {
  return new URLSearchParams(
    Object.entries(
      pick(coords || {}, [
        "latitude",
        "longitude",
        "accuracy",
        "altitude",
        "altitudeAccuracy",
        "speed",
        "heading",
        "headingAccuracy",
      ])
    )
      .filter(([a, b]) => !!b)
      .map(([a, b]) => [a, b + ""])
  );
}

function storeSinglePermit(json) {
  if (!json) return json;
  updateItems(merge({}, json.items, ...map(json, (value, key) => value.items))); // old style
  return json;
}

function processLegacyItems(json) {
  if (!json) return json;
  each(json, (value, key) => {
    //console.log(key, value);
    //if(has(value, "items")) json.items[key] = value; //.items;
    if (has(value, "items")) set(json, ["items", key], value);
  });
  return json;
}

export async function fetchAndStorePermits(ids) {
  if (!ids) return {};
  if (typeof ids === "string") ids = [ids];
  if (!ids || !ids.length) return {};
  const res = await fetch(
    `${base()}/permits?viewpoint=${viewpoint()}${ids
      .map((id) => "&permit=" + id)
      .join("")}`
  );
  const json = await res.json();
  updateItems(json);
  return json;
}

export async function responseJson(response) {
  if (!response) return {};
  return response
    .text()
    .then(function (text) {
      if (!text)
        return {
          status: response.status,
        };

      return Promise.resolve(text)
        .then(JSON.parse)
        .catch(function (error) {
          return {
            status: response.status,
            message: text,
          };
        });
    })
    .catch(function (error) {
      return {
        status: response.status,
      };
    });
}

function resolveAddress(item, items) {
  if (!item) return item;
  item.address = items[item.address] || item.address;
  return item;
}

export function resolveProperty(item, items) {
  if (!item) return item;
  if (typeof item === "string") item = items[item];
  return resolveAddress(item, items);
}

export const fetchAndStoreProperty = debounce(
  async function (property) {
    const json = await Promise.all([
      fetch(
        `${base()}/properties?viewpoint=${viewpoint()}&property=${property}`
      ),
    ])
      .then((values) => Promise.all(values.map((res) => res.json())))
      //.then(values => (values.map(json => pick(json, "items"))))
      .then((values) => merge({}, ...values));

    processLegacyItems(json);

    updateItems(json);
  },
  1000,
  {
    trailing: true,
    leading: true,
  }
);

export async function fetchPlateObservation(property, blob, coords) {
  const photo = await image.resize(
    blob,
    {
      width: 1280,
      height: 1280,
    },
    "image/jpeg",
    0.45
  );

  //console.log(URL.createObjectURL(photo));

  const data = new FormData();
  data.append("image", photo, `image${Date.now()}.jpg`);

  const url = new URL(
    `https://events.propertyboss.io/lprd/image?viewpoint=${viewpoint()}&scope=${
      property.id || property
    }&method=scanner&${coordsToURLSearchParams(
      coords
    ).toString()}${authHeader}`
  );
  if (property?.vehicles?.recognition?.engine)
    url.searchParams.set("alpr", property.vehicles.recognition.engine);

  let res = await fetch(url, {
    method: "POST",
    body: photo,
  });
  let json = await responseJson(res);
  return json;
}

export async function fetchMediaObservation(property, blob, media, coords) {
  const photo = await image.resize(
    blob,
    {
      width: 1280,
      height: 1280,
    },
    "image/jpeg",
    0.45
  );

  //console.log(URL.createObjectURL(photo));

  const data = new FormData();
  data.append("image", photo, `image${Date.now()}.jpg`);

  let res = await fetch(
    `${base()}/observations?viewpoint=${viewpoint()}&scope=${
      property.id || property
    }&media=${
      (media && media.id) || media || ""
    }&method=scanner&${coordsToURLSearchParams(
      coords
    ).toString()}${authHeader}`,
    {
      method: "POST",
      body: data,
    }
  );
  let json = await responseJson(res);
  return json;
}

export async function fetchVehicles(scope) {
  if (!scope) return null;
  const json = await Promise.all([
    fetch(
      `${base()}/vehicles?scope=${scope}&viewpoint=${viewpoint()}${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  return json;

  //state.update(prev => merge(prev, json.items));
}

export async function fetchSpaces(scope) {
  if (!scope) return null;
  const json = await Promise.all([
    fetch(
      `${base()}/spaces?scope=${scope}&viewpoint=${viewpoint()}${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  return json;

  //state.update(prev => merge(prev, json.items));
}

export async function fetchMedia(scope) {
  if (!scope) return null;
  const json = await Promise.all([
    fetch(
      `${base()}/media?scope=${scope}&viewpoint=${viewpoint()}${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  return json;

  //state.update(prev => merge(prev, json.items));
}

export async function fetchUnits(scope, sent) {
  if (!scope) return null;
  const json = await Promise.all([
    fetch(
      `${base()}/units/tenants?scope=${scope}&sent=${
        sent === true
      }&viewpoint=${viewpoint()}&valid=${encodeURIComponent(
        new Date().toISOString() + "/" + new Date().toISOString()
      )}${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  return json;

  //state.update(prev => merge(prev, json.items));
}

export async function fetchAndStoreVehicles(scope) {
  const json = await fetchVehicles(scope);

  processLegacyItems(json);

  updateItems(json);

  //state.update(prev => merge(prev, json.items));
}

export async function fetchAndStoreMedia(scope) {
  const json = await fetchMedia(scope);

  processLegacyItems(json);

  updateItems(json);

  //state.update(prev => merge(prev, json.items));
}

export async function fetchAndStoreUnits(scope) {
  const json = await fetchUnits(scope);

  processLegacyItems(json);
  updateItems(json);

  //state.update(prev => merge(prev, json.items));
}

export async function fetchAndStoreSpaces(scope) {
  const json = await fetchSpaces(scope);

  processLegacyItems(json);

  updateItems(json);

  //state.update(prev => merge(prev, json.items));
}
